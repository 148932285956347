import { useState } from "react";
import List from "../../list/list";
import quotesData from "../../data/data.json";
import "./favourites.scss";
import EmptyFavorite from "./emptyFavorite";
export default function Favourites() {
  const theme = sessionStorage.getItem("theme");

  type Quote = {
    id: string;
    text: string;
  };

  type QuotesData = {
    [key: string]: Quote[];
  };

  function getQuotesByIds(quotesData: QuotesData, quoteIds: string[]): Quote[] {
    const selectedQuotes: Quote[] = [];
    for (const category in quotesData) {
      const categoryQuotes = quotesData[category];
      for (const quoteId of quoteIds) {
        const foundQuote = categoryQuotes.find((quote) => quote.id === quoteId);
        if (foundQuote) {
          selectedQuotes.push(foundQuote);
        }
      }
    }
    return selectedQuotes;
  }

  const getLocalIds = localStorage.getItem("favourites");
  const selectedIds = getLocalIds ? JSON.parse(getLocalIds) : [];
  const selectedQuotes = getQuotesByIds(quotesData, selectedIds);

  return (
    <div className={`container favourites_container ${theme ? theme : ""}`}>
      <ul className="list">
        {selectedIds.length >= 0 &&
          selectedQuotes?.map((item: any) => <List item={item} />)}
        {selectedIds.length <= 0 && <EmptyFavorite />}
      </ul>
    </div>
  );
}
