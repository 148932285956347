export default function Button({ onClick, children, likedQuotes, id }: any) {
  const handleBtnClick = () => {
    onClick();
  };
  return (
    <button className="button_" onClick={handleBtnClick}>
      {children}
    </button>
  );
}
