import List from "../../list/list";
import quotesData from "../../data/topData.json";
import "./topQuotes.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function TopQuotes(params: any) {
  const [data, setData] = useState(quotesData);
  const navigate = useNavigate();
  const theme = sessionStorage.getItem("theme");
  useEffect(() => {
    if (sessionStorage.getItem("hasVisited") !== "true") {
      // Якщо ні, зберігаємо цю інформацію
      sessionStorage.setItem("hasVisited", "true");

      // Редірект на іншу сторінку
      navigate("/categories");
    }
  }, []);

  return (
    <div className={`container  top_quotes ${theme ? theme : ""}`}>
      <ul className="list">
        {data.map((item) => (
          <List item={item} />
        ))}
      </ul>
    </div>
  );
}
