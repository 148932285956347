import { NavLink } from "react-router-dom";

export default function EmptyFavorite() {
  return (
    <div className="empty">
      <p className="empty_text">
        There is nothing to show yet. Please review the quotes and add it via
        choosing{" "}
      </p>
      <NavLink to={"/"} className="empty_link">
        Choose topic
      </NavLink>
    </div>
  );
}
