import SvgIcon from "../svg/svgImport";
import "./nav.scss";
import { NavLink } from "react-router-dom";
export default function Nav() {
  const theme = sessionStorage.getItem("theme");

  return (
    <div className={`container nav_container ${theme ? theme : ""}`}>
      <div className="nav_link_container">
        <NavLink
          to="/categories"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          <SvgIcon.quotes className="svg_icon" />
        </NavLink>
      </div>
      <div className="nav_link_container">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          <SvgIcon.topQuotes className="svg_icon" />
        </NavLink>
      </div>

      <div className="nav_link_container">
        <NavLink
          to="/favorite-quotes"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          <SvgIcon.favoriteQuotes className="svg_icon" />
        </NavLink>
      </div>

      <div className="nav_link_container">
        <NavLink
          to="/setings"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          <SvgIcon.setings className="svg_icon" />
        </NavLink>
      </div>
    </div>
  );
}
