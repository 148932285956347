import "./quotes.scss";
import data from "../../data/data.json";
import List from "../../list/list";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type Quote = {
  id: string;
  text: string;
};

export default function QuotesPage() {
  const theme = sessionStorage.getItem("theme");

  const { category } = useParams();
  const [sortData, setSortData] = useState<Quote[]>([]);

  useEffect(() => {
    // Визначте тип для selectedCategory
    const selectedCategory = category as keyof typeof data;
    // Переконайтеся, що ви отримуєте правильний тип даних
    setSortData(
      (data[selectedCategory] as Quote[]) || Object.values(data).flat()
    );
  }, [category]);

  return (
    <div className={`container quotes_container ${theme ? theme : ""}`}>
      <ul className="list">
        {sortData.map((item) => (
          <List key={item.id} item={item} />
        ))}
      </ul>
    </div>
  );
}
