import { useState } from "react";
import Button from "../button/button";
import SvgIcon from "../svg/svgImport";

export default function List({ item, OnAddToFavorite }: any) {
  const getLikesLocal = () => {
    const lokalLike = localStorage.getItem("favourites");

    if (lokalLike) {
      // Парсимо рядок JSON, щоб отримати масив
      const likesArray = JSON.parse(lokalLike);
      return likesArray;
    }
    return [];
  };

  const [likedQuotes, setLikedQuotes] = useState<string[]>(
    getLikesLocal() || []
  );

  const addToLocal = (id: string) => {
    const lokalLikeArray: any = localStorage.getItem("favourites");
    const newArr = JSON.parse(lokalLikeArray);
    if (newArr.includes(id)) {
      const updatedArray = newArr.filter((item: any) => item !== id);
      localStorage.setItem("favourites", JSON.stringify(updatedArray)); // Зберегти оновлений масив
    } else {
      newArr.push(id);
      localStorage.setItem("favourites", JSON.stringify(newArr)); // Зберегти оновлений масив
    }
  };

  const AddToFavorite = (quoteId: string) => {
    setLikedQuotes((prevLikedQuotes: string[]) => {
      if (prevLikedQuotes.includes(quoteId)) {
        // Видаляємо ідентифікатор, якщо він вже існує в списку
        return prevLikedQuotes.filter((id) => id !== quoteId);
      } else {
        // Додаємо ідентифікатор, якщо його немає в списку
        return [...prevLikedQuotes, quoteId];
      }
    });
    addToLocal(quoteId);
  };

  const ifLiked = (id: string) => {
    if (!likedQuotes.includes(id)) {
      return false;
    } else {
      return true;
    }
  };
  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Не вдалося скопіювати текст: ", err);
    }
  };

  const handleShareClick = () => {
    console.log("custom function");
  };

  return (
    <li key={item.id} className="list_item">
      <p className="text">{item.text}</p>
      <button
        className={`like_btn ${ifLiked(item.id) ? "liked" : ""}`}
        id={item.id}
        onClick={() => {
          AddToFavorite(item.id);
        }}
      >
        <SvgIcon.like className="like_svg" />
      </button>
      <Button
        onClick={() => {
          handleCopy(item.text);
        }}
      >
        <SvgIcon.copy className="copy_svg" />
      </Button>

      <div className="share_btn_container">
        <Button>
          <SvgIcon.share className="share_svg" onClick={handleShareClick} />
        </Button>
      </div>
    </li>
  );
}
