import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import data from "../../data/category.json";

import "./categories.scss";
import { NavLink } from "react-router-dom";
export default function Categories() {
  const theme = sessionStorage.getItem("theme");

  return (
    <div className={`container categories_container ${theme ? theme : ""}`}>
      <div></div>
      <div className="carousel">
        <Flicking align="center" circular={true} renderOnlyVisible={true}>
          {data.map((item, index) => (
            <div className="panel" key={index}>
              <NavLink to={`/qoutes/${item.category}`} className="link">
                <img
                  src={item.image}
                  alt={`Slide ${index + 1}`}
                  className="image"
                />
                <div className="text_container">
                  <p className="category_text">{item.text}</p>
                </div>
              </NavLink>
            </div>
          ))}
        </Flicking>
      </div>
    </div>
  );
}
