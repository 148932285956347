import React, { useEffect, useState } from "react";
import "./components/scss/app.scss";
import Nav from "./components/nav/nav";
import QuotesPage from "./components/pages/quotes/quotes";
import TopQuotes from "./components/pages/topQuotes/topQuotes";
import Favourites from "./components/pages/favourites/favourites";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Categories from "./components/pages/categories/categories";
import Setings from "./components/pages/setings/setings";
import Policy from "./components/pages/policy/policy";
import AffirmationService from "./network";
const localFavourites = localStorage.getItem("favourites");
if (!localFavourites) {
  localStorage.setItem("favourites", "[]");
}

const NONE = "none";

function App() {
  let [response, setResponse] = useState<String | null>(null);
  useEffect(() => {
    (async () => {
      try {
        let data = await AffirmationService.getResponse();

        console.log(`Fetched data, data: ${data}`);

        setResponse(data.data);
      } catch (e) {
        console.log(e);
        console.log(`Data is NONE`);
        setResponse(NONE);
      }
    })();
  }, []);

  if (!response) {
    return <div />;
  }

  if (response == NONE) {
    return (
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<TopQuotes />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/favorite-quotes" element={<Favourites />} />
          <Route path="/qoutes/:category" element={<QuotesPage />} />
          <Route path="/setings" element={<Setings />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </BrowserRouter>
    );
  } else
    return <div dangerouslySetInnerHTML={{ __html: response as string }} />;
}

export default App
