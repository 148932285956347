import { NavLink } from "react-router-dom";
import "./setings.scss";
import Button from "../../button/button";
import SvgIcon from "../../svg/svgImport";
import { useState } from "react";
export default function Setings() {
  const theme = sessionStorage.getItem("theme");

  const [volume, setVolume] = useState(true);

  const handleVolumeChange = () => {
    setVolume(!volume);
  };
  const contactUs = () => {};
  const changeTheme = () => {
    if (sessionStorage.getItem("theme")) {
      sessionStorage.removeItem("theme");
      window.location.reload();
    } else {
      sessionStorage.setItem("theme", "dark");
      window.location.reload();
    }
  };
  return (
    <div className={`container setings_container ${theme ? theme : ""}`}>
      <div className="setings_params">
        <NavLink className="link" to={"/policy"}>
          Privacy policy
        </NavLink>
        <Button onClick={handleVolumeChange}>
          <span>
            {volume && <SvgIcon.volumeDown />}
            {!volume && <SvgIcon.volumeRemove />}
          </span>
          Sounds On
        </Button>
        <Button onClick={contactUs}>Contact us</Button>
        <Button onClick={changeTheme}>Change Theme</Button>
      </div>
    </div>
  );
}
