import { ReactComponent as favoriteQuotes } from "./favorite-quotes.svg";
import { ReactComponent as like } from "./like.svg";
import { ReactComponent as quotes } from "./quotes.svg";
import { ReactComponent as topQuotes } from "./top-quotes.svg";
import { ReactComponent as setings } from "./setings.svg";
import { ReactComponent as copy } from "./copy.svg";
import { ReactComponent as volumeRemove } from "./VolumeRemove.svg";
import { ReactComponent as volumeDown } from "./VolumeDown.svg";
import { ReactComponent as share } from "./share.svg";




const SvgIcon = {
  favoriteQuotes,
  like,
  quotes,
  topQuotes,
  setings,
  copy,
  volumeDown,
  volumeRemove,
  share
};
export default SvgIcon;
